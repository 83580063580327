.cnt-container {
    width: 100%;
    height: auto;
    background: rgba(248, 254, 255, 0.99);
}

.cnt-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cnt1 {
    border-radius: 5px;
    width: 90%;
    height: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/src/image/contacthero.png);
    background-position: center;
    background-size: cover;
    flex-direction: column;
}

.cnt1 h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: #ffffff;
}

.cnt1 p {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    color: #ffffff;
    width: 70%;
}

.contact-form {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.contactInfo h3 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #0f0738;
}

.contactInfo h3 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #0f0738;
}

.contactInfo {
    width: 600px;
    margin: 20px;
    color: #100a38;
}

.contactInfo>p {
    font-family: "Outfit";
    font-style: normal;
    color: #0f0738;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
}

hr {
    height: 0px;
    /* left: 179px;
    top: 960.5px; */
    border: 1px solid #6b778c;
}

.formm {
    width: 450px;
    height: 100%;
    min-height: 40vh;
    padding: 20px 30px;
    background: rgba(196, 196, 196, 0.21);
    border-radius: 10px;
    margin: 10px 25px;
}

.formm>h4 {
    align-self: center;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #100a38;
}

.formm>input {
    width: 100%;
    background: #ffffff;
    height: 60px;
    border: none;
    margin: 10px 5px;
    outline-color: #e20082;
    padding-left: 20px;
}

.formm .btn-submit {
    width: 176px;
    height: 55px;
    background: linear-gradient(285deg, #f00b51, #e20082);
    border-radius: 27px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}