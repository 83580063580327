@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;500;600;700&display=swap");
body {
    font-family: "Outfit", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Outfit" !important;
    /* align-items: ; */
}