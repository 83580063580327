.career-section {
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

.career-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.career-hero {
    width: 90%;
    border-radius: 5px;
    height: 100%;
    min-height: 50vh;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/src/image/career.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.career-hero>h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    text-align: center;
}

.career-section>p {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    color: white;
    width: 20em;
    text-align: center;
}