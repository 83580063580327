.career-section2 {
    background-color: transparent;
    width: 100%;
    min-height: 70vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #0f0738;
    flex-wrap: wrap;
}

.career-section2 .text-content {
    padding: 20px;
    width: 500px;
}

.career-section2 .text-content>h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #0f0738;
}

.career-section2 .text-content>p {
    background-color: transparent;
    font-family: "Outfit";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
}

.section2-images {
    width: 650px;
    min-height: 300px;
    height: 100%;
    /* background: red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.see-all-openings {
    /* background-color: #d80c1b; */
    background: linear-gradient(285deg, #f00b51, #e20082);
    font-weight: 600;
    font-size: 16px;
    color: white;
    border-radius: 10px;
    outline: none;
    border: 0;
    padding: 16px 40px;
}

.job-modal h3,
.job-modal p,
.job-modal h5 {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .see-all-openings {
        width: 90%;
    }
    .career-section2 {
        /* display: block; */
        flex-direction: column-reverse;
    }
    .career-section2 .text-content>p {
        width: 90%;
    }
    .career-section2 .text-content>h1 {
        font-size: 30px;
    }
    .career-section2 .text-content {
        margin-left: 0;
        width: 100%;
    }
    .section2-images {
        width: 100%;
    }
}