@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }
    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }
    .pin {
        width: 15vw;
    }
    .pin-icon {
        font-size: 10vw;
    }
}

.maps {
    width: 100%;
    height: 100%;
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 400px;
    width: 60rem;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (max-width: 768px) {
    .mapouter,
    .gmap_canvas {
        height: 500px;
        width: 400px;
    }
}