.privacy-container {
    width: 80%;
    margin: auto;
    font-size: 1rem;
}

.privacy-container>h1 {
    margin-top: 50px;
    font-size: 1.7rem;
    text-align: center;
}

.list-header {
    font-weight: 700;
    font-size: 1.1rem;
}

.list-header-desc {
    font-size: 1.02rem;
}

@media (max-width: 768px) {
    .privacy-container {
        width: 100%;
        padding: 15px;
    }
    .privacy-container ol {
        margin-left: 0;
    }
}