@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.aboutContent {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    font-family: "Outfit";
    margin-top: 80px;
}

.aboutContentImg {
    width: 500px;
    transition: 0.5s all ease-in-out;
    /* display: none; */
}

.aboutContentImg:hover {
    transform: scale(1.5);
}

.aboutContentText {
    width: 40%;
}

.aboutContentText>h3 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #0f0738;
}

.aboutContentText>p {
    font-size: 16px;
    color: #0f0738;
}

@media (max-width: 1200px) {
    .aboutContentText {
        width: 400px;
        margin-top: 0;
        text-align: justify;
    }
    .aboutContentImg {
        width: 400px;
        transition: 0.5s all ease-in-out;
    }
}

@media (max-width: 900px) {
    .aboutContentText {
        width: 350px;
        margin-top: 30px;
        text-align: justify;
    }
    .aboutContentImg {
        width: 350px;
        transition: 0.5s all ease-in-out;
    }
}

@media (max-width: 768px) {
    .aboutContentText {
        width: 100%;
        margin-top: 60px;
        text-align: justify;
    }
    .aboutContentImg {
        width: 90%;
        transition: 0.5s all ease-in-out;
    }
}