.career-section3 {
    width: 100%;
    min-height: 70vh;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
    /* background: red; */
}

.careerSection3-text {
    background-color: transparent;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* margin: 3px 20px; */
    padding: 10px;
}

@media (max-width: 768px) {
    .careerSection3-text {
        width: 100%;
    }
}

.careerSection3-text>h2 {
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    color: #0f0738;
    font-size: 1.7rem;
}

.careerSection3-text>p {
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 38px; */
    color: #6b778c;
    /* text-align: center; */
}