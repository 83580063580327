@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.faq-container {
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

.hero-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faqHero {
    width: 90%;
    height: 100%;
    min-height: 50vh;
    border-radius: 5px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/src/image/faqImg/faqmockup.png);
    /* background: url(/src/image/abouthero.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.faqHero h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    text-align: center;
    color: #ffffff;
}