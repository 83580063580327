.image3-container {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 650px;
    /* width: 100%; */
    min-height: 50vh;
    height: 100%;
    position: relative;
}

.image3-container .image3 {
    background: #d9d9d9;
    width: 90%;
    min-height: 50vh;
    height: 100%;
}

.iframe-video {
    width: 80%;
    height: 50vh;
    padding: 20px;
}

.iframes {
    width: 500px;
    height: 400px;
}

@media (max-width: 758px) {
    .iframes {
        width: 100%;
    }
    .image3-container {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .iframes {
        width: 450px;
    }
    .image3-container {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .iframes {
        width: 350px;
    }
    .image3-container {
        width: 100%;
    }
}