.last-section {
    background-color: transparent;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    padding: 15px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #0d223d;
}

.last-section-text {
    /* width: 40%; */
    width: 500px;
    background: transparent;
    border-radius: 10px;
    background: transparent;
    font-family: outfit;
    margin: 10px 30px;
    /* padding: 30px 30px; */
}

.last-section-text>h1 {
    font-weight: 600;
    color: #0f0738;
    font-size: 24px;
}

.last-section-text ul li {
    padding: 5px;
    margin: 0;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    color: #6e7a8f;
    font-size: 14px;
}

.last-section-image {
    width: 650px;
}

@media (max-width: 1200px) {
    .last-section-image img {
        width: 450px;
        /* display: none; */
    }
    .last-section {
        flex-direction: column;
    }
    .last-section-image {
        width: 500px;
    }
    .last-section-text {
        width: 500px;
        margin: 0;
    }
}

@media (max-width: 900px) {
    .last-section-image img {
        width: 450px;
        /* display: none; */
    }
    .last-section {
        flex-direction: column;
    }
    .last-section-image {
        width: 500px;
    }
    .last-section-text {
        width: 500px;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .last-section-image img {
        width: 100%;
        /* display: none; */
    }
    .last-section {
        flex-direction: column;
    }
    .last-section-image {
        width: 70%;
    }
    .last-section-text {
        width: 100%;
        margin: 0;
    }
}