.terms-container {
    width: 80%;
    margin: auto;
    overflow: hidden;
    padding-top: 30px;
}

.terms-container h4 {
    color: #0f0738;
}

ol li {
    display: list-item;
}

p {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
}

h4 {
    padding-left: 0;
    /* color: #0f0738; */
}