@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.abt-container {
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

.hero-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.abtHero {
    width: 90%;
    height: 100%;
    min-height: 50vh;
    border-radius: 5px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/src/image/aboutHero2.jpeg);
    /* background: url(/src/image/abouthero.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.abtHero h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    text-align: center;
    color: #ffffff;
}

.our-vision {
    width: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.our-vision p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
}

.mission {
    width: 100%;
    height: 100%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mission p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    width: 70%;
    text-align: center;
}

.Aboutpage-section2 {
    width: 100%;
    min-height: 50vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Our-promises {
    width: 70%;
    /* padding: 50px; */
}

.Our-promises>h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
}

.Our-promises h1,
.our-vision h1,
.mission h1 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #0f0738;
}

.Our-promises p,
.Our-promises span,
.our-vision p,
.mission p {
    font-size: 20px;
    color: #0f0738;
}

.single-image {
    width: 100%;
    min-height: 30vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.members-container {
    background-color: 0f0738;
}

.hello {
    position: absolute;
    width: 140px;
    height: 45px;
    left: 130px;
    top: 80px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #ffffff;
}

@media (max-width: 768px) {
    .Our-promises {
        width: 100%;
        padding: 10px;
    }
    .our-vision p,
    .mission p {
        font-size: 20px;
        width: 100%;
    }
    .abtHero p {
        width: 100%;
        font-size: 1rem;
    }
    .aboutContentText>h3 {
        text-align: center;
    }
    .single-image img {
        width: 90%;
    }
}