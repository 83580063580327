.modal-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.6);
    /* display: none; */
}

.modals {
    max-width: 480px;
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    /* min-height: 30vh; */
    /* height: 100%; */
    /* margin: 120px auto; */
    margin: 0 auto;
    /* text-align: center; */
    background-color: whitesmoke;
    padding: 30px;
    border-radius: 10px;
    color: #0f0738;
    position: relative;
    animation: drop 0.5s ease forwards;
}

@keyframes drop {
    0% {
        opacity: 0;
    }
    70% {
        transform: translateY(120px);
    }
    100% {
        transform: translateY(110px);
        opacity: 1;
    }
}

@media (max-width: 600px) {
    .modals {
        max-width: 350px;
    }
}